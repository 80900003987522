import request from '/utils/request';
// 一级导航
export function headNavigationList(params) {
  return request({
    url: '/finance/Reception/topLists',
    method: 'get',
    params,
  });
}
// 二级导航
export function headNavigationSubcolumn(params) {
  return request({
    url: '/finance/Reception/getTopList',
    method: 'get',
    params,
  });
}

// 公司概况
export function companyProfileList(id) {
  return request({
    url: '/finance/Reception/overviewList?webColumnsId=' + id,
    method: 'get',
  });
}

// 部门设置
export function companyDepartmentSetting(params) {
  return request({
    url: '/finance/Reception/deptList',
    method: 'get',
    params,
  });
}

// 新闻列表
export function newsListpAGE(params) {
  return request({
    url: '/finance/Reception/articlePageList',
    method: 'get',
    params,
  });
}

// 新闻详情
export function companyArticleDetails(params) {
  return request({
    url: '/finance/Reception/articleDetails',
    method: 'get',
    params,
  });
}

// 部门设置详情
export function companyDeails(id) {
  return request({
    url: '/finance/Reception/deptDetails?id=' + id,
    method: 'get',
  });
}

// 轮播图
export function slideshowList(id) {
  return request({
    url: '/finance/Reception/getFiles?id=' + id,
    method: 'get',
  });
}

// 网点分类

export function branchclassify() {
  return request({
    url: '/finance/Reception/networkType',
    method: 'get',
  });
}

// 文章预览接口

export function articleInterface(id) {
  return request({
    url: '/finance/Reception/getArticleDetails?id=' + id,
    method: 'get',
  });
}

// 客服热线
export function customerServiceHotline() {
  return request({
    url: '/finance/Reception/getHotline',
    method: 'get',
  });
}

// 网点昵称
export function dotNickname() {
  return request({
    url: '/finance/Reception/topList',
    method: 'get',
  });
}

// 搜索
export function searchList(params) {
  return request({
    url: '/finance/Reception/searchArticle',
    method: 'get',
    params,
  });
}

// 搜索详情
export function searchDetails(params) {
  return request({
    url: '/finance/Reception/searchArticleDetails',
    method: 'get',
    params,
  });
}

// 主题色
export function grayedList() {
  return request({
    url: '/finance/Reception/getWebsiteTheme',
    method: 'get',
  });
}

// ETC车生活资讯（轮播图列表）
export function etcLifeInformation() {
  return request({
    url: '/finance/Reception/getETCLifeInfoImg',
    method: 'get',
  });
}

export function WebsiteManagementDetails(id) {
  return request({
    url: '/financeWebsiteColumns/details?id=' + id,
    method: 'get',
  });
}

// 轮播时间
export function bannerSpace() {
  return request({
    url: 'finance/Reception/bannerSpace',
    method: 'get',
  });
}
