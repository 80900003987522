import request from '/utils/request';
// 查询服务网点列表
export function searchNetwork(params) {
  return request({
    url: '/financeNetworkInfo/search',
    method: 'post',
    params,
  });
}
// 网点分类

export function branchclassify() {
  return request({
    url: '/finance/Reception/networkType',
    method: 'get',
  });
}