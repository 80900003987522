import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
Vue.config.productionTip = false;
import { MY_ENV_VARIABLE } from './api/env-variables'; //引入环境变量
Vue.prototype.$MY_ENV_VARIABLE = MY_ENV_VARIABLE; // 访问环境变量
const MODELACCESS = Vue.prototype.$MY_ENV_VARIABLE; // 注入vue实例化
export { MODELACCESS }; // 暴露导出
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import store from './store';
// 引入百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'HELOmwkyuOv5HKlhZj0AnCwbTc9mn2Qi',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
