<template>
  <div class="box">
    <div class="Fixed_icon">
      <a target="_blank" :href="itemUrl.address">
        <img
          width="100%"
          class="onLine"
          v-if="itemUrl.iconUri"
          :src="itemUrl.iconUri"
          alt=""
        />
        <img class="onLine" width="100%" v-else :src="iconType.url" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import { customerServiceHotline } from '../../../../api/index';
export default {
  name: 'JietongofficialwebsiteuiIndex',

  data() {
    return {
      itemUrl: {
        iconUri: '',
        address: '',
      },
      Path: '',
      iconType: {
        url: require('../../../../assets/allImage/homePage/zaixiankefu1@1x.png'),
      },
    };
  },
  async created() {
    const res = await customerServiceHotline();
    this.itemUrl.address = res.data.data.address;
    this.itemUrl.iconUri = this.Path + res.data.data.iconUri;
  },
  mounted() {
    this.Path = process.env.VUE_APP_BASE_URL;
    // this.$store.dispatch('service');
  },
  // computed: {
  //   ...mapState(['serviceHotLine']),
  // },
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  position: relative;
  .Fixed_icon {
    width: 200px;
    position: fixed !important;
    z-index: 2;
    bottom: 5rem;
    right: 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .onLine {
      padding: 1.25rem 0;
      width: 80%;
    }
  }
}
</style>
