import axios from 'axios';
// vuex插件数据存储
import createPersistedState from 'vuex-persistedstate';
import store from '../src/store/index';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在请求拦截器中清除本地缓存
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
