<template>
  <div
    id="app"
    :style="{
      filter: isGrayedOut == true ? 'grayscale(100%)' : '',
    }"
  >
  <FixedIcon></FixedIcon>
    <router-view></router-view>
  </div>
</template>

<script>
import FixedIcon from '@/layout/components/HomePage/FixedIcon/index.vue';
import { grayedList } from '@/api/index.js';
export default {
  components: {
    FixedIcon,
  },
  name: 'App',
  data() {
    return {
      isGrayedOut: true, // 默认为false，表示未启用灰白效果
    };
  },
  created() {
    //在页面加载时读取localStorage里的状态信息
    localStorage.getItem('userMsg') &&
      this.$store.replaceState(
        Object.assign(
          this.$store.state,
          JSON.parse(localStorage.getItem('userMsg'))
        )
      );
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('userMsg', JSON.stringify(this.$store.state));
    });
  },
  // 配置标签页图标
  async mounted() {
    const res = await grayedList();
    if (res.data.code == 200) {
      if (res.data.data.theme == 'GREY') {
        this.isGrayedOut = true;
      } else {
        this.isGrayedOut = false;
      }
    }
    const icon = document.querySelector("link[rel*='icon']");
    icon.href = require('./assets/icon/logo.png');
  },
};
</script>

<style>
body {
  margin: 0;
}
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.grayed {
  filter: grayscale(100%) !important;
}

.grayed::before {
  filter: grayscale(100%) !important;
}
</style>
