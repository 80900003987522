import vueRouter from 'vue-router';
// import { MODELACCESS } from '../main';
import Vue from 'vue';

Vue.use(vueRouter);
const routes = [
  {
    path: '/',
    redirect: '/layout',
  },
  {
    meta: { title: '首页' },
    path: '/layout',
    name: 'layout',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/index.vue'),
      },
      {
        path: 'index',
        name: 'Index',
        meta: { titie: '首页', icon: 'none' },
        hidden: true,
        component: () => import('../views/index.vue'),
      },
      {
        path: 'index:id',
        name: 'Index',
        meta: { titie: '首页', icon: 'none' },
        hidden: true,
        component: () => import('../views/index.vue'),
      },
      {
        path: 'companyProfile',
        name: 'CompanyProfile',
        meta: { titie: '公司概况', icon: 'none' },
        hidden: true,
        component: () =>
          import('../views/compontents/companyProfile/index.vue'),
        children: [
          {
            path: '',
            name: 'Comm',
            meta: { titie: '', icon: 'none' },
            hidden: true,
            component: () => import('../views/comm/index.vue'),
          },
          {
            path: 'monomer',
            name: 'danti',
            meta: { titie: '', icon: 'none' },
            hidden: true,
            component: () =>
              import('../views/CompanyProfile/singlePage/index.vue'),
          },
          {
            path: 'public',
            name: 'public',
            meta: { titie: '', icon: 'none' },
            hidden: true,
            component: () => import('../views/publicSearchPage/index.vue'),
          },
          {
            path: 'articlePreviews',
            name: 'articlePreviews',
            meta: { titie: '文章预览', icon: 'none' },
            hidden: true,
            component: () => import('../views/articlePreviews/index.vue'),
          },
          {
            path: 'contentDetails',
            name: 'ContentDetails',
            meta: { titie: '详情', icon: 'none' },
            hidden: true,
            component: () => import('../views/contentDetailscomm/index.vue'),
          },
          {
            path: 'details',
            name: 'details',
            meta: { titie: '详情', icon: 'none' },
            hidden: true,
            component: () =>
              import('../views/publicSearchPage/detailsPage/index.vue'),
          },
          {
            path: 'etcguideDetails',
            name: 'etcguideDetails',
            meta: { titie: '', icon: 'none' },
            hidden: true,
            watchQuery: true,
            component: () =>
              import('../views/compontents/etcguide/guideDetails/index.vue'),
          },
          {
            path: 'comm/:id',
            name: 'Comm',
            meta: { titie: '二级公共路由', icon: 'none' },
            hidden: true,
            component: () => import('../views/comm/index.vue'),
          },
        ],
      },
      {
        path: 'serviceNetworkStation',
        name: 'ServiceNetworkStation',
        meta: { titie: '服务网点', icon: 'none' },
        hidden: true,
        component: () =>
          import('../views/compontents/serviceNetworkStation/index.vue'),
      },
      {
        path: 'etcguide',
        name: 'ServiceHall',
        meta: { titie: 'ETC指南', icon: 'none' },
        hidden: true,
        component: () => import('../views/compontents/etcguide/index.vue'),
        children: [],
      },
      {
        path: 'carLife',
        name: 'CarLife',
        meta: { titie: 'ETC车生活资讯', icon: 'none' },
        hidden: true,
        component: () => import('../views/compontents/carLife/index.vue'),
      },
      {
        path: 'presscenter',
        name: 'Presscenter',
        meta: { titie: '新闻中心', icon: 'none' },
        hidden: true,
        component: () => import('../views/compontents/presscenter/index.vue'),
      },
      {
        path: 'manpowerResource',
        name: 'ManpowerResource',
        meta: { titie: '人力资源', icon: 'none' },
        hidden: true,
        component: () =>
          import('../views/compontents/manpowerResource/index.vue'),
      },
      {
        path: 'biddingInformation',
        name: 'BiddingInformation',
        meta: { titie: '招标信息', icon: 'none' },
        hidden: true,
        component: () =>
          import('../views/compontents/biddingInformation/index.vue'),
      },
      {
        path: 'featureColumn',
        name: 'FeatureColumn',
        meta: { titie: '专题专栏', icon: 'none' },
        hidden: true,
        component: () => import('../views/compontents/featureColumn/index.vue'),
      },
      {
        path: 'report',
        name: 'report',
        meta: { titie: '信访举报指南', icon: 'none' },
        hidden: true,
        component: () => import('../views/compontents/report/index.vue'),
      },
    ],
  },
  {
    meta: { title: '404' },
    path: '*',
    name: '404',
    component: () => import('../views/error/404.vue'),
  },
  {
    path: '/home',
    component: () => import('../views/compontents/home/index.vue'),
  },
];

// 防止连续点击多次路由报错
let routerPush = vueRouter.prototype.push;
vueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

const router = new vueRouter({
  mode: 'history', // 去掉url中的#
  routes,
});

router.beforeEach((to, from, next) => {
  const userAgent = navigator.userAgent;
  // 移动端
  if (userAgent.indexOf('Mobile') !== -1) {
    // 获取浏览器地址
    var fullUrl = window.location.href;
    // 切割域名
    var domainName = fullUrl.split('/layout')[0];
    // 处理路径
    // var disposePath = domainName.substring(0, domainName.lastIndexOf('/')); // 去掉最后一个斜杠
    // 拼接完整地址
    var allAdderss = domainName + '/' + 'h5' + '/' + '#' + '/' + 'layout';
    // 移动设备
    // window.open(allAdderss, '_blank');
    window.location.href = allAdderss;
  } else if (
    userAgent.indexOf('Windows') !== -1 ||
    userAgent.indexOf('Macintosh') !== -1 ||
    userAgent.indexOf('Linux') !== -1
  ) {
    // 如果是使用电脑端访问就直接放行，否者就跳转手机端
    next();
  } else {
    // 其他设备
    console.log('不是手机不是电脑');
  }
});

export default router;
