const singList = {
  namespaced: true,
  state: {
    // 新闻列表
    list: [
      {
        id: 0,
        content: 'vue作者优雨溪',
      },
    ],
    totalt: 20,
    // 路由id
  },
  mutations: {},
  actions: {},
};

export default singList;
