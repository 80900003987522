import Vue from 'vue';
import Vuex from 'vuex';
// // vuex插件数据存储
// import createPersistedState from 'vuex-persistedstate';
import singList from './modules/singlePage';
import { mapList } from '../api/map';
import {
  newsListpAGE,
  slideshowList,
  companyProfileList,
  companyDepartmentSetting,
  companyArticleDetails,
  companyDeails,
  branchclassify,
  customerServiceHotline,
  bannerSpace,
} from '../api/index';
import { headGuideList } from '@/api/HomePage/guide.js';
import { searchNetwork } from '@/api/NetworkStation/index.js';

Vue.use(Vuex);
const store = new Vuex.Store({
  columnPageType: '',
  columnPageStyle: '',
  isSubStyle: '',
  // 持久化数据保存
  // plugins: [createPersistedState()],
  // 一级导航index
  firstIndexNumb: 0,
  modules: {
    singList,
  },
  state: {
    // 轮播时间
    bannerTime: 5,
    dataPrompt: false, // 提示是否显示站务数据
    // 全局分页
    overallQueryParams: {
      pageNum: 1,
      pageSize: 10,
      columnId: '',
    },
    commColumnId: '', //公共分页id
    fullscreenLoading: false, // 加载
    wholeTotal: 0, // 全局分页
    depwholeTotal: 0, // 部门分页
    secondLevel: {
      columnsName: '',
      id: '',
    }, // 点击一级导航默认二级导航第一个
    commonNavigationList: [], // 公共导航数据
    statusTypes: 0, // 类型状态 0二级状态  1当前状态
    dynamicParentName: {}, // 二级导航昵称切换
    newList: [], // 新闻列表
    companyList: [], //公司列表
    departmentList: [], //部门列表
    articleDetails: [], //新闻详情
    Details: [],
    pageIdentity: '', //页面标识     LIST 列表页    MONOMER 单体页
    upAndDown: {}, //详情上页昵称
    bomAndDown: {}, //详情下页昵称
    carouselList: [], // 轮播图
    GuideDetails: [], //ETC指南详情
    networkList: [], // 网点管理
    map_BaiduList: [], //地图网点列表
    mapListClassification: [],
    serviceHotLine: {}, // 客服热线
    dantiyeList: [],
  },
  mutations: {
    // 二级单体页
    _SINGLEPAGE(state, data) {
      // console.log('啊甘啊', data);
      // var obj = {}

      if (data.length > 0) {
        state.companyList.push(data[0]);
      }
    },
    SERVICE(state, data) {
      state.serviceHotLine = data;
    },
    commitHttps(state, data) {
      state.commColumnId = data;
    },
    // 一页显示几条
    globalpagingPageNum(state, data) {
      state.overallQueryParams.pageNum = data.pageNum;
      state.overallQueryParams.pageSize = data.pageSize;
      state.overallQueryParams.columnId = data.columnId;
    },

    // 改变公共导航昵称
    commNavName(state, data) {
      state.secondLevel.columnsName = data.columnsName;
      state.secondLevel.id = data.id;
    },
    TNOTTINGH0RSELAMP(state, data) {
      state.carouselList = data;
    },

    changeState(state, data) {
      state.statusTypes = data;
    },
    firstIndex(state, data) {
      state.firstIndexNumb = data;
    },
    // 接收actios的一部操作
    SET_PERMISSIONS(state, data) {
      state.wholeTotal = data.total; //分页长度
      state.newList = data.records;
      if (state.newList.length == 0) {
        state.dataPrompt = true;
      }
    },
    sectionXq(state, data) {
      state.wholeTotal = data; //部门列表分页长度
    },

    // 公司框概况
    CORPORATION(state, data) {
      state.companyList = data;
    },
    // 部门列表
    DEPARTMENT(state, data) {
      state.departmentList = data;
      if (state.departmentList.length == 0) {
        state.dataPrompt = true;
      }
    },

    ARTICLEDETAILS(state, data) {
      state.articleDetails = data;
    },
    // 部门详情
    DEPARTMENTDEAILS(state, data) {
      state.Details = data;
    },
    // etc指南详情
    FUIDEDEATILS(state, data) {
      state.GuideDetails = data;
    },
    // 服务网点
    SEARCHNETWORK(state, data) {
      state.networkList = data;
    },
    // 地图初始化数据
    MAP_BAIDU(state, data) {
      state.map_BaiduList = data;
    },
    // 网点分类
    // 网点分类
    BRANCHCLASSIFY(state, data) {
      let emptyclass = { typeName: '全部', uuId: '' };
      state.mapListClassification = data;
      state.mapListClassification.unshift(emptyclass);
    },
    // 单体页一级导航

    DANTIYE(state, data) {
      state.dantiyeList = data;
    },
    // 轮播时间
    BANNERSPACE(state, data) {
      if (!data) {
        state.bannerTime = 5;
      } else {
        state.bannerTime = data;
      }
    },
  },
  actions: {
    // 轮播时间
    async bannerSpace({ commit }) {
      const res = await bannerSpace();
      if (res.status == 200) {
        commit('BANNERSPACE', res.data.data.approveVar1);
      }
    },
    // 新闻模块一级发请求跟二级区发请求，返回值不一样，已经返回值会多了一个records层级
    async newsListFn({ state, commit }, payload, statusType) {
      state.companyList = [];
      const res = await newsListpAGE(payload);
      //  处理二级单体页
      if (res.data.code == 200) {
        if (res.data.data.records && res.data.data.records.length > 0) {
          var form = {};
          form.id = res.data.data.records[0].id;
          form.columnId = payload.columnId;
          const resDils = await companyArticleDetails(form);
          // 提交公司概况
          commit('_SINGLEPAGE', resDils.data.data);
        }
      }
      commit('SET_PERMISSIONS', res.data.data);
    },
    // 一级导航只有单体页时
    async SinglePageFnst({ state, commit }, payload) {
      const res = await newsListpAGE(payload);
      commit('DANTIYE', res.data.data.records);
    },

    // 公司概况
    async companyProfileList({ state, commit }, payload) {
      state.companyList = [];
      const res = await companyProfileList(payload);
      commit('CORPORATION', res.data.data);
    },
    // 部门设置
    async companyDepartmentSetting({ commit }) {
      const res = await companyDepartmentSetting();
      commit('sectionXq', res.data.data.total);
      commit('DEPARTMENT', res.data.data.records);
    },
    // 新闻详情
    async companyArticleDetails({ commit }, payload) {
      const res = await companyArticleDetails(payload);
      commit('ARTICLEDETAILS', res.data.data);
    },
    // 部门详情
    async companyDeails({ commit }, payload) {
      commit('DEPARTMENTDEAILS', res.data.data);
    },
    // 轮播图
    async publicRotationChart({ commit }, payload) {
      const res = await slideshowList(payload);

      if (res.data.code) {
        commit('TNOTTINGH0RSELAMP', res.data.data);
      }
    },
    // ETC指南
    async headGuideList({ commit }, payload) {
      const res = await headGuideList(payload);
      if (res.data.code) {
        commit('FUIDEDEATILS', res.data.data);
      }
    },
    // 服务网点搜索
    async searchNetwork({ commit }, payload) {
      const res = await searchNetwork(payload);
      if (res.data.code) {
        commit('SEARCHNETWORK', res.data.data);
      }
    },
    // 地图
    async mapFn({ commit }, payload) {
      const res = await mapList(payload);
      if (res.data.code) {
        commit('MAP_BAIDU', res.data.data);
      }
    },
    // 网点分类管理
    async Dottion({ commit }, payload) {
      const res = await branchclassify();
      if (res.data.code) {
        commit('BRANCHCLASSIFY', res.data.data);
      }
    },

    // 客服热线
    async service({ commit }, payload) {
      const res = await customerServiceHotline();
      commit('SERVICE', res.data.data);
    },
  },
});
export default store;
